<template>
    <div
            v-if="show"
            class="transaction-search"
            :class="navBarShow ? 'transaction-search-no-wechat' : ''"
    >
        <van-field
                v-model="goodsValue"
                label-align="right"
                :readonly="withGoods"
                :label="$t('产品')"
                :placeholder="$t('产品')"
                @focus="goodsShow = true"
        >
            <template #right-icon>
                <van-icon name="close" v-if="!withGoods" @click.stop="clearPicker('goods')"/>
                <div v-if="goodsColumns.length > 0 && goodsShow" class="picker-main">
                    <div
                            v-for="(item, index) in goodsColumns"
                            :key="'goods' + index"
                            class="picker-list"
                            @click="selectPicker('goods', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field
                v-model="cusValue"
                v-if="withCus"
                :readonly="withCus"
                label-align="right"
                :label="$t('客户')"
                :placeholder="$t('客户')"
                @focus="cusShow = true"
        >
            <template #right-icon>
                <van-icon name="close" v-if="!withCus" @click.stop="clearPicker('cus')"/>
                <div v-if="cusColumns.length > 0 && cusShow" class="picker-main">
                    <div
                            v-for="(item, index) in cusColumns"
                            :key="'cus' + index"
                            class="picker-list"
                            @click.stop="selectPicker('cus', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field
                label-align="right"
                v-model="spec"
                clearable
                :label="$t('规格')"
                :placeholder="$t('规格')"
        />
        <van-field
                v-model="price"
                label-align="right"
                clearable
                :label="$t('价格')+'/'+$t('美元')"
                :placeholder="$t('价格')+'/'+$t('美元')"
                type="number"
        />
        <van-field
                v-model="number"
                label-align="right"
                clearable
                :label="$t('数量')"
                :placeholder="$t('数量')"
                type="number"
        />
        <van-field name="radio" label-align="right" :label="$t('交易方式')">
            <template #input>
                <van-radio-group v-model="trade_method_price" direction="horizontal">
                    <van-radio name="FOB">FOB</van-radio>
                    <van-radio name="CIF">CIF</van-radio>
                    <van-radio name="CIP">CIP</van-radio>
                    <van-radio name="EXW">EXW</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field name="radio" label-align="right" :label="$t('交易方式')">
            <template #input>
                <van-radio-group v-model="trade_method_method" direction="horizontal">
                    <van-radio name="AIR">AIR</van-radio>
                    <van-radio name="SEA">SEA</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
                v-model="trade_method_port"
                label-align="right"
                clearable
                :label="$t('gy_cp_trade_port')"
                :placeholder="$t('gy_cp_trade_port')"
        />
        <van-field
                v-model="memo"
                label-align="right"
                clearable
                :label="$t('其他信息')"
                :placeholder="$t('其他信息')"
                type="textarea"
        />
        <div class="login-right taglibs" v-if="multCus">
            <van-checkbox-group class="van-checkbox-group--horizontal " v-model="tagSel">
                <van-checkbox class="van-checkbox--horizontal taglibs-item" shape="square"
                              v-for="(item,i) in taglibList" :key="i"
                              :name="item.id" >{{item.name}}
                </van-checkbox>
            </van-checkbox-group>
        </div>
        <van-field
                v-model="cusSelValue"
                v-if="multCus"
                label-align="right"
                :label="$t('指定客户')"
                :placeholder="$t('指定客户')"
                @focus="cusSelShow = true"
        >
            <template #right-icon>
                <van-icon name="close" v-if="multCus" @click.stop="clearPicker('cusSel')"/>
                <div v-if="cusSelColumns.length > 0 && cusSelShow" class="picker-main">
                    <div
                            v-for="(item, index) in cusSelColumns"
                            :key="'cusSel' + index"
                            class="picker-list"
                            @click.stop="selectPicker('cusSel', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <div class="custome-box">
            <van-tag
                    v-for="cus in cus_arr"
                    :key="cus.cp_id"
                    closeable
                    size="medium"
                    type="warning"
                    @close="delCusSel(cus)"
            >
                {{cus.cp_name}}
            </van-tag>
        </div>
        <van-button
                color="#5d74f2"
                class="transaction-search-btn"
                @click="doPublish"
        >{{ $t("提交") }}
        </van-button
        >
        <van-overlay :show="loadFlag">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="34px" color="#FFFFFF" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import utils from "../utils/utils";
    import {Toast} from "vant";

    export default {
        name: "GoodsPrice",
        data() {
            return {
                id: 0,
                withCus:false,
                withGoods:false,
                multCus:false,

                goodsValue: "",
                cusValue: "",
                cusSelValue: "",
                spec: "",
                number: "",
                trade_method_price: "",
                trade_method_method: "",
                trade_method_port: "",
                price: "",
                memo: "",
                cus_ids:[],

                cus_arr:[],//已选择的客户企业

                taglibList: [],//标签列表
                tagSel: [],

                navBarShow: false, // 是否有顶部导航栏
                show: false, // 搜索框显示
                cusList: [], // 供应商列表
                goodsList: [], // 产品列表
                goodsColumns: [], // 产品选择器显示数据
                cusColumns: [], // 供应商选择器显示数据
                cusSelColumns: [], // 供应商选择器显示数据
                goodsShow: false, // 产品下拉框显示隐藏
                cusShow: false, // 供应商下拉框显示隐藏
                cusSelShow: false, // 供应商下拉框显示隐藏
                loadFlag:true,//加载层
            };
        },
        props: {
            item: {
                // 传入数据
                type: Object,
                default: () => {
                },
            },
        },
        watch: {
            goodsValue(val) {
                this.pickerkeywordInput("goods", val);
            },
            cusValue(val) {
                this.pickerkeywordInput("cus", val);
            },
            cusSelValue(val) {
                this.pickerkeywordInput("cusSel", val);
            },
            id(val){
                //填充数据
                if(val!=0){
                    this.initData();
                }
            },
        },
        mounted() {
            if (!utils.isWechat()) {
                this.navBarShow = true;
            }
            this.companyTagList();
        },
        methods: {
            toggleShow() {
                // 询盘框显示隐藏
                this.show = !this.show;
            },
            companyTagList(){
                let that = this;
                this.$http.post("/v1/getTagsList").then((res) => {
                    if (res.code == 200) {
                        that.taglibList = res.data.tagList;
                        // console.log(that.taglibList);
                    }
                });
            },
            doPublish() {
                // 发布询盘
                let that = this;
                that.loadFlag = true;
                this.$http
                    .post("/v1/doPublishPrice", {
                        id: this.id,
                        pg_name: this.goodsValue,
                        cus_name: this.cusValue,
                        spec: this.spec,
                        number: this.number,
                        price: this.price,
                        trade_method_price: this.trade_method_price,
                        trade_method_method: this.trade_method_method,
                        trade_method_port: this.trade_method_port,
                        memo: this.memo,
                        cus_ids:this.cus_ids,
                        tags:this.tagSel,
                    })
                    .then((res) => {
                        that.loadFlag = false;
                        if (res.code == 200) {
                            Toast(this.$t("发布成功"));
                            // this.toggleShow();
                        }
                    });
            },
            setCusId(){
                let that = this;
                if(!this.withCus){
                    return;
                }
                that.loadFlag = true;
                this.$http.post("/v1/cusDetail",{cus:this.cusValue,type:'basic'}).then((res) => {
                    if (res.code == 200) {
                        that.cus_ids.push(res.data.cp.id);
                        that.loadFlag = false;
                    }
                });
            },
            prepareEmptyData(){
                this.loadFlag = true;
                setTimeout(() => {
                    this.cus_arr = [];
                    this.cus_ids = [];
                    this.goodsValue = "";
                    this.withCus = false;
                    this.multCus = true;
                    this.spec = "";
                    this.number = "";
                    this.price = "";
                    this.trade_method_price = "";
                    this.trade_method_method = "";
                    this.trade_method_port = "";
                    this.memo = "";
                    this.tagSel = [];

                    this.loadFlag = false;
                }, 1000);

            },
            initData(){
                let that = this;
                that.loadFlag = true;
                this.$http.post("/v1/getPriceInfo",{id:this.id}).then((res) => {
                    if (res.code == 200) {
                        let info = res.data.info;
                        that.goodsValue = info.pg_name;
                        that.withCus = false;
                        that.spec = info.spec;
                        that.number = info.number;
                        that.price = info.price;
                        that.trade_method_price = info.tm_arr.price;
                        that.trade_method_method = info.tm_arr.method;
                        that.trade_method_port = info.tm_arr.port;
                        that.memo = info.memo;
                        that.tagSel = info.ct_arr;
                        that.cus_arr = info.cus_arr;
                        that.cus_ids = info.cus_ids;

                        that.multCus = true;
                        that.loadFlag = false;
                        // console.log(that.multCus);
                    }
                });
            },
            setCusArr(value){
                let that = this;
                this.$http.post("/v1/selCusForPrice",{
                    cus_arr:this.cus_arr,
                    cus:value,
                }).then((res) => {
                    if (res.code == 200) {
                        that.cus_arr = res.data.cus_arr;
                        that.cus_ids = res.data.cus_ids;
                        that.cusSelValue = "";
                    }
                });
            },
            delCusSel(obj){
                this.cus_arr = this.cus_arr.filter(
                    (item)=>item&&item.cp_id!=obj.cp_id
                );
                this.cus_ids = this.cus_ids.filter(
                    (cp_id)=>cp_id>0&&cp_id!=obj.cp_id
                );
                console.log(this.cus_ids);
            },

            clearPicker(type) {
                // 清楚选择
                this[type + "Value"] = "";
            },
            selectPicker(type, value) {
                // 选择下拉项
                this[type + "Value"] = value;
                this[type + "Columns"] = [];
                this[type + "Show"] = false;
                if(type=='cusSel'){
                    //获取企业ID
                    this.setCusArr(value);
                }
            },
            pickerkeywordInput(type, val) {
                //  关键字变化事件
                if (val) {
                    let url = "";
                    switch (type) {
                        case "goods":url = "/v1/poolGoods";break;
                        case "cus":url = "/v1/poolCus";break;
                        case "cusSel":url = "/v1/poolCus";break;
                    }
                    this.$http.post(url,{val:val}).then((res) => {
                        if (res.code == 200) {
                            let columns = res.data.list.filter(
                                (item) => item && item.toLowerCase().indexOf(val.toLowerCase()) != -1
                            );
                            this[type + "Columns"] = columns;
                        }
                    });
                } else {
                    this[type + "Columns"] = [];
                }
            },
        },
    };
</script>

<style scoped>
    .transaction-search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding-bottom: 5vh;
        overflow: auto;
    }

    .transaction-search-no-wechat {
        top: 46px !important;
        height: calc(100vh - 46px);
    }
    .transaction-search-btn {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        width: 100vw;
        height: 40px;
        box-sizing: border-box;
    }
    .placeholder-title {
        color: #ccc;
    }


    .picker-main {
        position: absolute;
        top: 33px;
        right: 0;
        width: 100%;
        border: 1px solid #ccc;
        z-index: 1;
        background: rgb(0,0,0,0.6);
        border-radius: 3px;
        max-height: 75vh;
        overflow: auto;
        color:#FFF;
    }

    .picker-list {
        text-align: left;
        padding: 3px 10px;
        border-bottom: 1px solid #e1e1e1;
    }

    .van-cell {
        overflow: initial !important;
    }

    .more-title {
        text-align: center;
        margin-top: 5vw;
        color: royalblue;
    }

    .van-radio--horizontal {
        margin: 3px 5px;
    }
    .login-right {
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* box-shadow: 0 0 15px 0 #ccc; */
    }
    .taglibs {
        align-content: center;
        flex-direction: column;
    }

    .taglibs-item {
        padding: 5px 4px;
    }
    .custome-box{
        padding-bottom: 50px;
        min-height: 10vh;
    }
    .van-tag{
        margin: 2px 5px;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>